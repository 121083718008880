/*
 * Polyfill para IE
 * dependency: utils/detectIE_version.js
 */

(function (POLP_boot, $) {

    // .img-aspect-original
    // .img-aspect-16by9
    // .img-aspect-1by1
    // .img-aspect-4by3

    POLP_boot.iePolyfillImgAspectRatio = function () {
        var detectIE_version = detectIE();
        if (detectIE_version >= 12 || detectIE_version === true) {
            //alert('IE');
            $(".img-wrapper").each(function () {
                var $container = $(this);
                var imgUrl = $container.find("img").prop("src");
                if (imgUrl) {
                    $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("polyfill-css-object-fit");
                }
            });
        }
    }

}(window.POLP_boot = window.POLP_boot || {}, jQuery, undefined));

////////////////////////////////////////////////////////////////////////////////
// Vanilla version 

/*
 //DomReady.ready(function () {
 document.addEventListener('DOMContentLoaded', function() {
 
 var detectIE_version = detectIE();
 if (detectIE_version >= 12 || detectIE_version === true) {
 //alert('IE');
 var el = document.getElementsByClassName('img-wrapper');
 
 Array.prototype.forEach.call(el, function (element, index, array) {
 //el.addEventListener('blur', textInputBlurHandler);
 });
 
 $(".img-wrapper").each(function () {
 var $container = $(this);
 var imgUrl = $container.find("img").prop("src");
 if (imgUrl) {
 $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("polyfill-object-fit");
 }
 });
 }
 
 });
 */