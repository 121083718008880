////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

(function (POLP_boot, $) {

  POLP_boot.domFade = function () {
    // animacion css: body{opacity:0};
    $('body').addClass('DomFade');
    //$('body.login').addClass('DomFade');
  };

  // Vanilla js -> detect device & orientation
  // resaponde al cambio de orientacion y añade atributos (data-) al body
  POLP_boot.bodyDataDevice = function (device) {
    //console.log(device);
    // require php Mobile_Detect
    $('body').attr('id', 'body');
    $('body').attr('data-device', device);
    $('body').attr('data-orientation', '');

    // device element
    //document.querySelectorAll('[data-device]');
    var dataDevice = document.querySelectorAll('[data-device]');
    //console.log(dataDevice);
    $.each(dataDevice, function (index, value) {
      $(value).attr('data-device', device);
    });

    // orientacion
    var query = window.matchMedia("(orientation:landscape)");
    //console.log(query.matches ? "landscape" : "portrait");
    $('*').find('[data-orientation]').attr('data-orientation', query.matches ? "landscape" : "portrait");
    var toggleOrientation = function (query) {
      $('*').find('[data-orientation]').attr('data-orientation', query.matches ? "landscape" : "portrait");
    }
    query.addListener(toggleOrientation);
  };


  POLP_boot.scrollToAnchor = function () {
    /////////////////////////////////////////////////////////////////////////
    // Smooth Scrolling To Internal Links
    // http://www.paulund.co.uk/smooth-scroll-to-internal-links-with-jquery
    // data-role="scrollto"
    //$('a[href^="#"][data-scrollto="true"]').on('click', function (e) {
    $('a[href^="#"][data-scrollto-anchor]').click(function (e) {
      //e.stopPropagation();
      e.preventDefault();

      // smooth, normal
      var anchorType = $(this).attr("data-scrollto-anchor");
      if (anchorType == 'smooth') {
        var speed = 'normal';
        var easing = 'easeInCirc';
      } else if (anchorType == 'normal') {
        var speed = 0;
        var easing = false;
      }

      //
      //console.log( $(this).attr("data-scrollTo-offset") );
      var offset = $(this).attr("data-scrollto-offset");
      var offsetScroll;
      if (typeof offset !== typeof undefined && offset !== false) {
        offsetScroll = offset;
      } else {
        offsetScroll = 0;
      }

      var urlHash = $(this).attr("data-scrollto-hash");

      //console.log( $(this).prop("data-scrollTo-offset") );
      var target = this.hash;
      //console.log($(target));
      //if( $(target).length && !$(target).is(":visible") ){
      if ($(target).length) {
        setTimeout(function () {
          $('html, body').animate({
            'scrollTop': ($(target).offset().top) - offsetScroll
          }, speed, easing, function () {

          });
        }, 50);

        if (urlHash) {
          window.location.hash = target; // deja el hash # URL, comentar para no ponerlo
        }
      }
    });
  }


}(window.POLP_boot = window.POLP_boot || {}, jQuery, undefined));
