////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
import autosize from 'autosize';
//import bytesToSize from 'bytesToSize';
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

//console.log('hola');

$(document).ready(function ($) {

  ////////////////////////////////////////////////////////////////////////////
  // thirdparty

  // autosize
  // http://www.jacklmoore.com/autosize/
  // https://github.com/jackmoore/autosize
  // autosize(document.querySelectorAll('textarea'));

  ////////////////////////////////////////////////////////////////////////////
  // POLP_cli.asideNavbar();

});

$(window).on('load', function () {
  //
  POLP_boot.domFade();

  ////////////////////////////////////////////////////////////////////////////
  POLP_boot.iePolyfillImgAspectRatio();
  POLP_boot.scrollToAnchor();
  //POLP_boot.dsg_formFileInput_dragAndDrop();

  ////////////////////////////////////////////////////////////////////////////
  // https://www.silvestar.codes/articles/building-an-animated-sticky-header-with-custom-offset/
  // POLP_boot.stickyHeader();

}); // fin jQuery(window).load
